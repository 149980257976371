
import { defineComponent, onBeforeMount, reactive, toRefs, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { indexApi } from '@/api/modules/index'

export default defineComponent({
  name: 'searchList',
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    onBeforeMount(() => {
      const { keyword } = route.query
      data.keyword = keyword
      data.getDocumentList()
      data.getHotIssueList()
    })

    /* 监听更新 */
    watch(
      () => route.query,
      (newVal) => {
        // console.log(newVal)
        const { keyword } = newVal
        if (keyword) {
          data.keyword = keyword
          data.getDocumentList()
          data.getHotIssueList()
        }
      }
    )

    const data: any = reactive({
      encryptData: store.state.app.encryptData,
      keyword: null,
      documentList: null,
      query: {
        page: 1,
        paginate: 20
      },
      hotIssueList: null,
      total: 0,

      async getDocumentList () {
        const keyword = data.keyword
        const { res } = await indexApi.handbookSearchList({
          keyword
        })
        console.log('操作手册列表', res)
        data.documentList = res
      },
      async getHotIssueList () {
        data.query.keyword = data.keyword
        const { res } = await indexApi.problemList(data.query)
        console.log('热门问题', res)
        data.hotIssueList = res.data
        data.total = res.total
      },

      // 每页显示条数和当前页码
      handleSizeChange (newSize: number) {
        data.query.paginate = newSize
        data.getHotIssueList()
      },
      handleCurrentChange (newPage: number) {
        data.query.page = newPage
        data.getHotIssueList()
      },

      // 页面跳转
      onDocumentDetails (id: number) {
        router.push(`/document/details?dataItem=${data.encryptData}&id=${id}`)
      },
      onHotIssueDetails (id: number) {
        router.push(`/hotIssue/details?dataItem=${data.encryptData}&id=${id}`)
      }
    })

    return {
      ...toRefs(data)
    }
  }
})
